export const supportedTimeZones: Array<string> = [
  //These time frames are in CDT, not CST and is the range of -4 to -10 for all us states.
  //America/Adak not supported by API
  //'America/Adak', // Alaskan (-9)
  'America/Anchorage', // Alaskan (-8)
  'America/Chicago', // Central (-5)
  'America/Denver', // Mountain (-6)
  'America/Los_Angeles', // Pacific (-7)
  'America/New_York', //Eastern (-4)
  'America/Phoenix', // Mountain (no ds -7)
  'Pacific/Honolulu', // Hawaii-Aleution (-10)
];

export const defaultTimeZone: string = 'America/Chicago'; // Central (-5)