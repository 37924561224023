export type LastDepositResult = {
  amount: number;
  date: string;
};

export type GrossSalesResult = {
  grossSales: number;
  trend?: number;
};

export type PaymentTypeDataResult = {
  paymentType: string;
  paymentTypePercentage: string;
  paymentTypeTotalAmount: string;
  paymentTypeTotalAmountFormatted: string;
  index: number;
};

export type BatchSummary = {
  merchantNumber: number;
  terminalDescription: string;
  batchId: string;
  batchNumber: string;
  transactionCount: number;
  netAmount: number;
  batchCreateDate: string;
  batchClosedDate: string;
  batchStatus: string;
  terminalNumber: string;
  totalRecordCount: number;
};

export type OpenBatchSummary = {
  lastUpdate: string;
  totalAmount: number;
};

export type Terminal = {
  terminalNumber: string;
  terminalDescription: string;
};

export enum BatchesSummaryPaging {
  SizeLimit = 4,
}

export type GroupedBatches = {
  totalAmount: number;
  totalTransactions: number;
  totalBatches: number;
  batches: Array<BatchSummary>;
};

export enum BatchRange {
  PreviousDay = 'PREVIOUS_DAY',
  Last7Days = 'LAST7_DAYS',
  Last14Days = 'LAST14_DAYS',
  Last30Days = 'LAST30_DAYS',
  Last60Days = 'LAST60_DAYS',
  Last90Days = 'LAST90_DAYS',
  Custom = 'CUSTOM',
}

export const batchRangeValue = {
  [BatchRange.PreviousDay]: 0,
  [BatchRange.Last7Days]: 1,
  [BatchRange.Last14Days]: 2,
  [BatchRange.Last30Days]: 3,
  [BatchRange.Last60Days]: 4,
  [BatchRange.Last90Days]: 5,
  [BatchRange.Custom]: 6,
}

export enum StatementRange {
  Last6Months = 'LAST6_MONTHS',
  Last12Months = 'LAST12_MONTHS',
  Last2Years = 'LAST2_YEARS',
  Last3Years = 'LAST3_YEARS',
  Custom = 'CUSTOM',
}

export const statementRangeValue = {
  [StatementRange.Last6Months]: 0,
  [StatementRange.Last12Months]: 1,
  [StatementRange.Last2Years]: 2,
  [StatementRange.Last3Years]: 3,
  [StatementRange.Custom]: 4,
}

export const StatementRangeOptions: Array<{ id: string; displayName: string }> = [
  {
    id: StatementRange.Last6Months,
    displayName: '6months',
  },
  {
    id: StatementRange.Last12Months,
    displayName: '12months',
  },
  {
    id: StatementRange.Last2Years,
    displayName: '2years',
  },
  {
    id: StatementRange.Last3Years,
    displayName: '3years',
  },
  {
    id: BatchRange.Custom,
    displayName: 'custom',
  },
];

export const DashboardComponentOptions = [
  { id: BatchRange.PreviousDay, displayName: 'previousDay' },
  { id: BatchRange.Last7Days, displayName: '7days' },
  { id: BatchRange.Last14Days, displayName: '14days' },
  { id: BatchRange.Last30Days, displayName: '30days' },
  { id: BatchRange.Last60Days, displayName: '60days' },
  { id: BatchRange.Custom, displayName: 'custom' },
];

export enum BatchStatus {
  Open = 'Open',
  Closed = 'Closed',
  All = 'All Statuses',
}

//-- This value is used to determine the distance from the top of the page to trigger the scroll to top button --//
export enum ScrollDistance {
  EndOfHeader = 150,
}

export const BatchRangeOptions: Array<{ id: string; displayName: string }> = [
  {
    id: BatchRange.Last30Days,
    displayName: 'Last 30 Days',
  },
  {
    id: BatchRange.Last90Days,
    displayName: 'Last 90 Days',
  },
  {
    id: BatchRange.Custom,
    displayName: 'Custom',
  },
];

export const BatchStatusOptions: Array<{ id: string; displayName: string }> = [
  {
    id: BatchStatus.All,
    displayName: 'All Statuses',
  },
  {
    id: BatchStatus.Open,
    displayName: 'Open',
  },
  {
    id: BatchStatus.Closed,
    displayName: 'Closed',
  },
];

export type StoredFilterOptions = {
  minDate: string | null;
  maxDate: string | null;
  selectedTerminals: Array<string>;
  range: BatchRange;
  status: BatchStatus;
};

export type selectOption = {
  id: string;
  displayName: string;
};

export enum BatchDetailKeys {
  BatchDetailsKey = 'batchDetails',
}

export type BatchDetails = {
  merchantNumber: string;
  batchId: string;
  batchNumber: string;
  transactionStatus: string;
  authCode: string;
  responseCode: string;
  connectivity: string;
  transactionType: string;
  icQualification: string;
  invoiceNumber: null | string;
  cardType: string;
  cardProduct: string;
  authAmount: number;
  amount: number;
  tipAmount: number;
  settledAmount: number;
  settledDateUTC: string;
  transactionDateUTC: string;
  transactionUUID: number;
  cardNumberMasked: null | string;
  cardExpirationDate: null | string;
  transactionCode: null | string;
  arn: null | string;
  terminalNumber: null | string;
  authDateUTC: null | string;
};

export type CardUsageSummary = {
  cardIcon: string;
  cardType: string;
  isGroupable: boolean;
  cardUsage: number;
  transactionCount: number;
  transactionTotal: number;
  refundCount: number;
  refundTotal: number;
  netSales: number;
  averageTransactions: number;
  transactionUUID: number;
};

export type SalesRepresentative = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  personOID: string;
};

export enum NotificationType {
  INFO = 'info',
}

export enum NotificationMessage {
  TIDCopied = 'Terminal ID Copied!',
  TransactionEnd = `No more data.`,
}

export const BatchHashState = '#scroll';

export const InitialTransactionConfigJSON = '{"range": "7days", "start": 7, "end": 1}';

export const DefaultDateFormat = 'M/dd/yyyy';
